import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { FaHospitalAlt } from "react-icons/fa";
import { FaHelmetSafety, FaOilWell, FaServer } from "react-icons/fa6";
import { BsBank2 } from "react-icons/bs";
import { RiGovernmentFill } from "react-icons/ri";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import FireBaseApp from '../../api/FireBaseConfig.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const storage = getStorage(FireBaseApp);

function Customers({ customersData }) {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [customerImages, setCustomerImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      const images = {};
      try {
        for (const customer of customersData.customers) {
          try {
            const imageUrl = await getDownloadURL(ref(storage, `Customers/${customer.img}`));
            images[customer.name] = imageUrl;
          } catch (error) {
            console.error(`Error fetching image for ${customer.name}:`, error);
            images[customer.name] = ''; // Fallback for missing images
          }
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setCustomerImages(images);
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [customersData]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const isActiveCategory = (category) => {
    return selectedCategory === category ? 'active' : '';
  };

  const filteredCustomers = selectedCategory === 'all'
    ? customersData.customers
    : customersData.customers.filter(customer => customer.category === selectedCategory);

  return (
    <section className="ProjectsCont" id='customers'>
      <h1 className="partners L4 font-semibold text-xl">Customers</h1>
      {selectedCategory !== 'all' && (
        <h3 className="category">{selectedCategory}</h3>
      )}
      <div className={selectedCategory === 'all' ? "vertical-buttons-all vstack gap-3" : "vertical-buttons vstack gap-3"}>
        <OverlayTrigger       
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">All customers</Tooltip>}>
          <button
            aria-label='all'
            className={`filter ${isActiveCategory('all')}`}
            onClick={() => handleCategoryChange('all')}
          >
            All
          </button>
        </OverlayTrigger>
        <OverlayTrigger       
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Construction</Tooltip>}>
          <button
            aria-label='construction'
            className={`filter ${isActiveCategory('construction')}`}
            onClick={() => handleCategoryChange('construction')}
          >
            <FaHelmetSafety className="filter-icon" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger       
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Oil and Gas</Tooltip>}>
          <button
            aria-label='oil and gas'
            className={`filter ${isActiveCategory('oil and gas')}`}
            onClick={() => handleCategoryChange('oil and gas')}
          >
            <FaOilWell className="filter-icon" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger       
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Medical</Tooltip>}>
          <button
            aria-label='medical'
            className={`filter ${isActiveCategory('medical')}`}
            onClick={() => handleCategoryChange('medical')}
          >
            <FaHospitalAlt className="filter-icon" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger       
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Banking</Tooltip>}>
          <button
            aria-label='banking'
            className={`filter ${isActiveCategory('banking')}`}
            onClick={() => handleCategoryChange('banking')}
          >
            <BsBank2 className="filter-icon" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger       
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Government</Tooltip>}>
          <button
            aria-label='government'
            className={`filter ${isActiveCategory('government')}`}
            onClick={() => handleCategoryChange('government')}
          >
            <RiGovernmentFill className="filter-icon" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger       
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Technology</Tooltip>}>
          <button
            aria-label='technology'
            className={`filter ${isActiveCategory('technology')}`}
            onClick={() => handleCategoryChange('technology')}
          >
            <FaServer className="filter-icon" />
          </button>
        </OverlayTrigger>
      </div>
      <ul className="projects">
        {isLoading ? (
          Array.from({ length: 10 }).map((_, index) => (
            <li key={index} className="project">
              <Skeleton height={100} width={200} />
            </li>
          ))
        ) : (
          <AnimatePresence>
            {filteredCustomers.map((customer, index) => (
              <motion.div
                key={index}
                layout
                initial={{ transform: "scale(0)" }}
                animate={{ transform: "scale(1)" }}
                exit={{ transform: "scale(0)" }}
              >
                <li className="project" key={index}>
                  {customerImages[customer.name] ? (
                    <img alt={customer.name} src={customerImages[customer.name]} />
                  ) : (
                    <Skeleton height={100} width={200} />
                  )}
                </li>
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </ul>
    </section>
  );
}

export default Customers;
