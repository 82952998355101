import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import ParticlesBackground from '../background/particles/particles';
import './solutions.css';
import FireBaseApp from '../../api/FireBaseConfig.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const storage = getStorage(FireBaseApp);

const Solutions = () => {
  const [data, setData] = useState(null);
  const [imageUrls, setImageUrls] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonUrl = 'solutions.json'; 
        const jsonRef = ref(storage, jsonUrl);
        const jsonResponse = await fetch(await getDownloadURL(jsonRef));
        const jsonData = await jsonResponse.json();
        setData(jsonData);

        const urls = {};
        for (const category in jsonData) {
          if (jsonData.hasOwnProperty(category)) {
            for (const item of jsonData[category]) {
              try {
                const imageUrl = await getDownloadURL(ref(storage, `Products/${category}/${item.img}`));
                urls[item.img] = imageUrl;
              } catch (error) {
                console.error(`Error fetching image for ${item.img}:`, error);
              }
            }
          }
        }
        setImageUrls(urls);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderCards = (category, items) => (
    <div className={`row grid justify-content-center category-section gap-3${category.toLowerCase().replace('_', '-')}`} id={category.toLowerCase().replace('_', '-')}>
      {console.log(category.toLowerCase().replace('_', '-'))}
      <h1 className='header'>{category.replace('_', ' ')}</h1>
      {isLoading ? (
        // Dynamically render skeletons based on the number of items in the category
        Array.from({ length: items.length }).map((_, index) => (
          <Card key={index} className="hidden LP">
            <Skeleton height={262} width={262} className="L3" />
            <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Skeleton height={30} width="60%" />
              <Skeleton height={50} width="90%" />
              <Skeleton height={35} width="40%" />
            </Card.Body>
          </Card>
        ))
      ) : (
        items.reduce((acc, item, index, array) => {
          if (index === 0 || item.manufacturer !== array[index - 1].manufacturer) {
            acc.push([]);
          }
          acc[acc.length - 1].push(item);
          return acc;
        }, []).map((group, groupIndex) => (
          <div className="row justify-content-center" key={groupIndex}>
            {group.map((item) => (
              <Card
                key={`${item.id}-${category}`}
                data-aos="fade-right"
                data-aos-delay={item.delay}
                className="card col-lg-3 col-md-4 col-sm-6 m-3"
                onClick={() => window.open(item.link, '_blank')}
                style={{ cursor: 'pointer' }}
              >
                <Card.Img
                  variant="top"
                  src={isLoading ? undefined : imageUrls[item.img]}
                  width="262px"
                  height="262px"
                  alt={`${category} product`}
                  className="S1"
                />
                <Card.Body>
                  <Card.Title>{item.text}</Card.Title>
                  <Card.Text className='card-sol-text'>
                    {item.description}
                  </Card.Text>
                  {item.coolingCapacities && (
                    <div>
                      {item.coolingCapacities.map((capacity, index) => (
                        <Badge key={index} bg="primary" className="me-1">
                          {capacity.type}: {capacity.capacity}
                        </Badge>
                      ))}
                    </div>
                  )}
                  {item.upsCapacity && (
                    <div>
                      <Badge bg="primary" className="me-1">
                        {item.upsCapacity}
                      </Badge>
                    </div>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
        ))
      )}
    </div>
  );
  
  

  return (
    <div className='sol'>
      <Helmet>
        <title>Solutions - ABC Engineering</title>
        <meta name="description" content="Explore our wide range of solutions including Precision Cooling, UPS, IT Infrastructure, and Raised Floor solutions. Find the perfect solution for your needs with ABC Engineering." />
        <meta name="keywords" content="Precision Cooling, UPS, IT Infrastructure, Raised Floor, PDU, ABC Engineering, solutions, Eaton, Batteries, Data Center, Egypt, False Ceiling, Transformers, Servers, Yuasa, Tecniair, Airdale, Weiss, Ortea, Saft" />
        <meta property="og:title" content="Solutions" />
        <meta property="og:description" content="ABC Engineering solutions" />
        <meta property="og:image" content="https://abc-eng.com/logo.webp" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://abc-eng.com/solutions" />
      </Helmet>
      <ParticlesBackground />
      <div className="container-sol container">
        {data && Object.keys(data).map((category) => renderCards(category, data[category]))}
      </div>
    </div>
  );
}

export default Solutions;
