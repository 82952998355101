import React, { Component, memo } from "react";
import { Helmet } from "react-helmet-async";
import Carousel from "react-bootstrap/Carousel";
import "./projects.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ParticlesBackground from '../background/particles/particles';
import FireBaseApp from '../../api/FireBaseConfig.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const storage = getStorage(FireBaseApp);

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: null,
      mediaUrls: {},
      activeProjectIndex: 0,
      activeImageIndex: 0,
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.fetchProjectsData();
  }

  fetchProjectsData = async () => {
    try {
      const jsonUrl = 'projects_media.json'; // Replace with your actual path
      const jsonRef = ref(storage, jsonUrl);
      const jsonResponse = await fetch(await getDownloadURL(jsonRef));
      const projectsData = await jsonResponse.json();
      this.setState({ projects: projectsData }, this.fetchMediaUrls);
    } catch (error) {
      console.error('Error fetching JSON data:', error);
    }
  };

  fetchMediaUrls = async () => {
    const { projects } = this.state;
    const mediaUrls = {};

    for (const project of projects) {
      try {
        const logoUrl = await getDownloadURL(ref(storage, `Projects/${project.name}/${project.logo}`));
        mediaUrls[project.logo] = logoUrl;
      } catch (error) {
        console.error(`Error fetching logo for ${project.logo}:`, error);
      }
      
      for (const media of project.media) {
        try {
          const mediaUrl = await getDownloadURL(ref(storage, `Projects/${project.name}/${media.src}`));
          mediaUrls[media.src] = mediaUrl;
        } catch (error) {
          console.error(`Error fetching media for ${media.src}:`, error);
        }
      }
    }

    this.setState({ mediaUrls, isLoading: false });
  };

  handleProjectSelect = (selectedIndex) => {
    this.setState({
      activeProjectIndex: selectedIndex,
      activeImageIndex: 0,
    });
  };

  handleImageSelect = (selectedIndex) => {
    this.setState({ activeImageIndex: selectedIndex });
  };

  render() {
    const { projects, mediaUrls, activeProjectIndex, activeImageIndex, isLoading } = this.state;
    const activeProject = projects ? projects[activeProjectIndex] : null;

    return (
      <div className="container projects-container">
        <Helmet>
          <title>{activeProject ? activeProject.title : ''} - ABC Engineering</title>
          <meta name="description" content="Explore our wide range of past projects with various leading tech industries." />
          <meta name="keywords" content="ABC Engineering, Projects, Past work, Mega projects" />
          <meta property="og:title" content="Projects" />
          <meta property="og:description" content="ABC Engineering projects" />
          <meta property="og:image" content="https://abc-eng.com/logo.webp" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://abc-eng.com/projects" />
        </Helmet>
        {isLoading && (
          <div className="loading-overlay">
            <img src={require("../../logo.webp")} alt="Loading..." className="loading-logo" />
          </div>
        )}
        <ParticlesBackground />
        <div className={`row projects-row ${isLoading ? 'hidden' : ''}`}>
          <h1 className="header-projects">Projects: {activeProject ? activeProject.title : ''}</h1>
          <div className="col-md-2 col-sm-6 project-icons">
            {projects && projects.map((project, index) => (
              <div
                key={project.id}
                className={`project-icon ${index === activeProjectIndex ? "active" : ""}`}
                onClick={() => this.handleProjectSelect(index)}
              >
                <img src={mediaUrls[project.logo]} alt={project.name} className="project-logo" />
              </div>
            ))}
          </div>
          <div className="col-md-10 col-sm-15 project-carousel">
            <Carousel
              activeIndex={activeImageIndex}
              onSelect={this.handleImageSelect}
              indicators={true}
              controls={true}
              interval={2000}
            >
              {activeProject && activeProject.media.map((media, index) => (
                <Carousel.Item key={index}>
                  <div className="project-image-container">
                    {media.type === 'image' ? (
                      <img src={mediaUrls[media.src]} alt="" />
                    ) : (
                      <video controls>
                        <source src={mediaUrls[media.src]} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <div className="image-counter">
                      {index + 1} / {activeProject.media.length}
                    </div>
                    <div className="carousel-caption">
                      <h3>{activeProject.title}</h3>
                      <p>{activeProject.description}</p>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

export default memo(Projects);
