import React, { Component, memo } from "react";
import { Helmet } from "react-helmet";
import { BsPower, BsSnow3 } from "react-icons/bs";
import { MdEngineering } from "react-icons/md";

import Products from "./Products.js";
import Partners from "./Partners.js";
import Customers from "./Customers.js";
import TextTransition, { presets } from 'react-text-transition';


import AOS from "aos";
import "aos/dist/aos.css";
import "./css/Landingpage.css";
import "./css/Partners.scss";
import FireBaseApp from '../../api/FireBaseConfig.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const storage = getStorage(FireBaseApp);



AOS.init({
  mirror: true,
  once: false,
  easing: "ease-in-out",
});

const TEXTS = ['PIONEERING', 'ADVANCED','CUTTING-EDGE', 'NEXT-GEN'];


class Landingpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      selectedCategory: 'all',
      projectsData: { customers: [], partners: [] }
    };
  }

  async componentDidMount() {
    AOS.init({ mirror: true, once: false, easing: "ease-in-out" });

    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({ index: prevState.index + 1 }));
    }, 3000);
    try {
      const jsonUrl = await getDownloadURL(ref(storage, 'projects.json'));
      const response = await fetch(jsonUrl);
      const data = await response.json();
      this.setState({ projectsData: data });
      } catch (error) {
      console.error('Error fetching projects:', error);
        }
    }
        
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  handleCategoryChange = (category) => {
    this.setState({ selectedCategory: category });
  };
  isActiveCategory = (category) => {
    return this.state.selectedCategory === category ? 'active' : '';
  }
  render() {
    
    const { partners } = this.state.projectsData;
    const { index } = this.state;

    return (
      <div className="homecontainer">
        <Helmet>
          <title>ABC Engineering</title>
          <meta name="description" content="Welcome to ABC Engineering, your partner for cutting-edge data center solutions. Our comprehensive suite of services includes Power Systems, Precision Cooling, and Civil Works, designed to elevate your data center's performance to new heights." />
          <meta name="keywords" content="Data Center, Power Systems, Precision Cooling, Civil Works, IT Infrastructure" />
          <meta property="og:title" content="Home" />
          <meta property="og:description" content="ABC Engineering homepage" />
          <meta property="og:image" content="https://abc-eng.com/logo.webp" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://abc-eng.com/" />
        </Helmet>

        <section id="homesec" className="section row px-0 align-self-center">
          <div className="centeredhome col-md-6 offset-3 mx-auto " data-aos="fade-up">
            <TextTransition springConfig={presets.molasses}>{TEXTS[index % TEXTS.length]}</TextTransition>
             DATA CENTER SOLUTIONS
          </div>
        </section>
        <div className="spacer layer1" />
        <section id="homeabout" className="section row px-0 align-items-center">
          <h1 className="ds L4 font-semibold text-xl">Data Center Solutions for the Future</h1>
          <div data-aos="fade-up" className="row" id="mainabout1">
            <div className="col">
              <div className="row d-block d-lg-none">
                Welcome to ABC Engineering, your partner for cutting-edge data center solutions.
                Our comprehensive suite of services includes Power Systems, Precision Cooling, and Civil Works, designed to elevate your data center's performance to new heights.
              </div>
              <div className="row d-none d-md-block">
                <p>
                  Welcome to ABC Engineering, where excellence meets innovation in data center solutions. With our unwavering commitment to quality,
                  we bring you a comprehensive suite of services, encompassing Power Systems, Precision Cooling, and Civil Works, all meticulously crafted to elevate your data center's
                  performance to new heights.
                </p>
                {/* <br />
                <p>
                  Our seasoned team of experts, backed by years of industry experience, takes pride in designing and optimizing data centers that set the standard for reliability and efficiency.
                  When you choose ABC Engineering, you're partnering with a trusted ally dedicated to delivering nothing less than exceptional results.
                </p> */}
              </div>
            </div>
            <div className="col d-none d-md-block d-flex align-items-center">
              <img className="aboutpic" alt="ABC" src={require("./Images/ser.webp")} />
            </div>
            <div className="row d-none d-md-block">
              <div className="container">
                <div className="row ">
                  <div className="col-md-10 align-items-center">
                    <br />
                    <h3>Our Expertise:</h3>
                    <blockquote className="blockquote">
                      <p className="aboutpara">
                        <BsPower /> Power Systems: Ensure a robust and stable electrical infrastructure for your data center's seamless operation.<br />
                        <BsSnow3 /> Precision Cooling: Keep your critical IT equipment at optimal temperatures with our advanced cooling solutions.<br />
                        <MdEngineering /> Civil Works: Our expert civil engineering team lays the foundation for a safe and efficient data center setup.<br />
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="spacer3 layer2" />
          <Products/>
        <div className="spacer3 layer3" />
          <Customers customersData={this.state.projectsData} />

        <div className="spacer3 layer4"></div>
        <div className="spacer3 layer5"></div>
        <section className="homesecpart">
          <h1 className="partners font-semibold text-xl">Partners</h1>
          <Partners partners={partners}/>
        </section>
      </div>
    );
  }
}

export default memo(Landingpage);
